import { motion, useViewportScroll, useTransform } from 'framer-motion';
import Punsh from './Punsh';
import React from 'react';


function About(){
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const {scrollY, scrollYProgress }= useViewportScroll(); /* för sista dividern */
    const scale = useTransform(scrollYProgress, [0.5,1],[0.5,1]); /* för sista dividern */
    return(
        <div>
            <div  className="heroAbout">
                <Punsh punsh="About" soft="" />
            </div>
            <div className="flexContainer">
                <div className="dividerContainer">
                    <img className="divider" src="/assets/martinDvider.png" alt="divider"></img>
                </div>
                <section className="about">
                    
                    <article className="article">
                        <div className="articleContent">
                            <img src="./assets/self.webp" alt="Me"></img>
                            
                        </div>
                                    
                    </article>

                    <article className="article">
                        <div className="articleContent aboutContent">
                        'Jag vill bli programmerare'. Det sa jag redan som barn. Efter två års studier är jag nu en nyexaminerad webbutvecklare/webmaster från Högskolan Väst i Trollhättan. <br/><br/>Redan på 1990-talet började jag programmera och det har alltid varit en del av mitt liv. Det jag mest fascineras av inom yrket är problemlösning och att skapa en länk mellan system och människa. 
<br/><br/>Som person är jag väldigt nyfiken och måste veta hur saker fungerar under huven. Att samarbeta med många olika människor faller mig lätt. Dels för att jag är uppväxt i två kulturer, men även för att jag har jobbat mot kund i många år.

<br/><br/>På min fritid ägnar jag mig åt sönerna Otto och Ralf, som ger mig spännande utmaningar varje dag. Intressen är – förutom att programmera så ofta jag kan – att laga mat och fördjupa mig i olika naturfilmer. En stor önskan är att få med mina barn på fler tältäventyr.
                        </div>
                
                
                    </article>

                    
                </section>

                <div className="dividerContainer">
                    <img className="divider" src="/assets/martinDvider.png" alt="divider"></img>
                </div>

                <section className="about">
                    <article className="article">
                        <a href="https://www.chrisportfolio.se" target="_blank"  rel="noreferrer">
                            <motion.div whileHover={{scale: 1.2}} className="articleContent">
                                <img src="./assets/co-op.webp" alt="Me"></img>
                            </motion.div>
                        </a>
                    </article>
                    <article className="article">
                        <div className="articleContent aboutContent">
                            Med solthet får jag berätta att jag påbörjat ett samarbete med Christopher Olsson. Han är en fullstack men med expertis och skarpa ögon på frontend.
                        </div>
                
                
                    </article>
                </section>

                <motion.div style={{scale}} className="dividerContainer">
                    <img className="divider" src="/assets/martinDvider.png" alt="divider"></img>
                </motion.div>

            </div>
        </div>
    )
};

export default About;